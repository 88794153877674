import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.anchor.js";
import "core-js/modules/es.string.replace-all.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-82fbe87e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  style: {
    "margin": "10px 0"
  }
};
var _hoisted_2 = {
  class: "flex-row"
};
var _hoisted_3 = {
  key: 1,
  class: "placeholder"
};
var _hoisted_4 = {
  class: "children"
};
var _hoisted_5 = {
  class: "flex-row"
};
var _hoisted_6 = {
  class: "placeholder"
};
import { inject, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { ArrowRightBold, SemiSelect } from "@element-plus/icons-vue";
export default {
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var route = useRoute();
    var closeDrawer = inject('closeDrawer', function () {});
    var state = reactive({
      showChildren: true
    }); // Computed

    /**
     * Determines if the link should be considered active; marks active heirarchy of links
     * to help inform the User what page & depth in the ToC they are on
     */

    var isActive = computed(function () {
      var path = props.entry.uri;
      var parts = path.split("/").filter(function (part) {
        return part;
      });
      var routePath = route.path.replace("/documentation/", "");
      var routeParts = routePath.split("/");
      return parts.every(function (part) {
        return routeParts.includes(part);
      });
    }); // Methods

    var toggleChildren = function toggleChildren() {
      state.showChildren = !state.showChildren;
    };
    /**
     * Determine if anchor link should be marked as active
     * @param {String} hash
     * @returns {Boolean}
     */


    var includesHash = function includesHash(hash) {
      return isActive.value && route.hash === "#".concat(hash);
    };

    return function (_ctx, _cache) {
      var _props$entry$childre, _props$entry$anchors;

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_router_link = _resolveComponent("router-link");

      var _component_TableOfContentsEntry = _resolveComponent("TableOfContentsEntry", true);

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_props$entry$childre = __props.entry.children) !== null && _props$entry$childre !== void 0 && _props$entry$childre.length || (_props$entry$anchors = __props.entry.anchors) !== null && _props$entry$anchors !== void 0 && _props$entry$anchors.length ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["show-more", {
          'active': _unref(state).showChildren
        }])
      }, [_createVNode(_component_el_icon, {
        size: 12,
        onClick: toggleChildren
      }, {
        default: _withCtx(function () {
          return [_createVNode(_unref(ArrowRightBold))];
        }),
        _: 1
      })], 2)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_icon, {
        size: 12
      }, {
        default: _withCtx(function () {
          return [_createVNode(_unref(SemiSelect))];
        }),
        _: 1
      })])), __props.entry.uri && __props.entry.post_title ? (_openBlock(), _createBlock(_component_router_link, {
        key: 2,
        to: "/documentation".concat(__props.entry.uri),
        class: _normalizeClass({
          'active-toc': _unref(isActive)
        }),
        onClick: _unref(closeDrawer)
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("h3", null, _toDisplayString(__props.entry.post_title), 1)];
        }),
        _: 1
      }, 8, ["to", "class", "onClick"])) : _createCommentVNode("", true)]), _withDirectives(_createElementVNode("div", _hoisted_4, [__props.entry.anchors ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(__props.entry.anchors, function (anchor, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          style: {
            "margin-top": "10px"
          }
        }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_icon, {
          size: 12
        }, {
          default: _withCtx(function () {
            return [_createVNode(_unref(SemiSelect))];
          }),
          _: 1
        })]), _createVNode(_component_router_link, {
          to: "/documentation".concat(anchor.uri, "#").concat(anchor.anchor),
          class: _normalizeClass({
            'active-toc': includesHash(anchor.anchor)
          }),
          onClick: _unref(closeDrawer)
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("h3", null, _toDisplayString(anchor.anchor.replaceAll('_', ' ')), 1)];
          }),
          _: 2
        }, 1032, ["to", "class", "onClick"])])]);
      }), 128)) : _createCommentVNode("", true), __props.entry.children ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(__props.entry.children, function (child, index) {
        return _openBlock(), _createBlock(_component_TableOfContentsEntry, {
          key: index,
          entry: child
        }, null, 8, ["entry"]);
      }), 128)) : _createCommentVNode("", true)], 512), [[_vShow, _unref(state).showChildren]])]);
    };
  }
};